import React from "react";
import UploadCard from "../UploadCard/UploadCard";
import * as styles from "./addvideo.module.scss";
import { useSelector } from "react-redux";

export default (props) => {
  let videos = useSelector(state => state.VideoSlice.videos)
  const uploadCards = videos.map((item, index) => {
      if(Object.keys(item).length !== 0){
        return (
          <UploadCard
            id={index}
            video={item.video_url}
            style={{ border: "none" }}
          />
        );
      }
      else {
        return null
      }

    });

    React.useEffect(() => {
      function getCorrectHeight(){
        setTimeout(() => {
          let cards = document.getElementsByClassName('upload_card')
          cards[cards.length - 1].style.height = cards[cards.length - 2].offsetHeight + 'px'
        }, 100)

      }
      function updateNextButton(){
        for(let i=0; i < videos.length; i++){
          if(videos[i].video_url){
            props.setNext(true)
            getCorrectHeight()
            return
          }
        }
        props.setNext(false)
        return
      }
      updateNextButton()

    },[videos])

  return(
      <div className={styles.cardsContainer}>
        {uploadCards}
        <UploadCard
          id={videos.length}
          style={{height: 500}}
        />
      </div>
  )
}