import AddIcon from '../../../../assets/svg/add.svg';
import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import SyncLoader from "react-spinners/SyncLoader";
import { toast } from 'react-toastify';
import DeleteSvg from '../../../../assets/svg/delete.svg';
import { deleteVideo, setVideoUrl } from '../../../../slices/video-slice';
import * as styles from "./uploadcard.module.scss";


const activeStyle = {
  borderColor: "#838897",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function getVideoDimensionsOf(url){
	return new Promise(function(resolve){
		// create the video element
		let video = document.createElement('video');

		// place a listener on it
		video.addEventListener( "loadedmetadata", function () {
			// retrieve dimensions
			let height = this.videoHeight;
			let width = this.videoWidth;
			// send back result
			resolve({
				height : height,
				width : width
			});
		}, false );

		// start download meta-datas
		video.src = url;
	});
}

function validateUpload(props){
  if(props){
    if(!props.type.includes('video')){
      toast.error('You must upload a video file.')
      return false
    }
    else if(props.size > 50*1024*1024){
        toast.error('The video size exceeds 50MB limit.')
        return false
      }
    return true
  }
  toast.error('You must upload a video file.')
  return false
}

export default (props) => {
  let dispatch = useDispatch()
  const [loader, setLoader] = React.useState(false);

  function uploadVideo(file) {
    const reader = new FileReader();
  
    reader.addEventListener("load", () => {
      // convert file to base64 string
      getVideoDimensionsOf(reader.result).then((dimensions) => {
        dispatch(setVideoUrl({id: props.id, url: reader.result, dimensions}))
      })

    }, false);
  
    reader.readAsDataURL(file);
  }


  const onDrop = async (acceptedFiles) => {
    setLoader(true);
    if(validateUpload(acceptedFiles[acceptedFiles.length - 1])){
      uploadVideo(acceptedFiles[acceptedFiles.length - 1])
    }
    setLoader(false);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: "video/*", onDrop });

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject]
  );

  return (
    <div className={`${styles.uploadCard} upload_card`} style={props.style}>
      {props.video ?
                <div className={styles.deleteDiv}>
                <DeleteSvg onClick={() => dispatch(deleteVideo(props.id))}/>
            </div> : null}
      <div {...getRootProps({ style })} style={{height: '100%'}}>
      <input {...getInputProps()} />
      <div
        style={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loader ? (
          <SyncLoader size={15} color={"#363636"} loading={true} />
        ) : props.video ? 
        <video width="100%" height="100%" controls>
  <source src={props.video} />
</video> : (
  <div className={styles.addDiv}>
              <AddIcon />
              <span>Add Video</span>
    </div>

        )}
      </div>
    </div>
    </div>
  );
};
