import CheckIcon from '../../../../assets/svg/check.svg';
import KeyboardBackspaceIcon from "../../../../assets/svg/backspace.svg";
import { Link } from "gatsby";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BarLoader from "react-spinners/BarLoader";
import AppStoreSvg from "../../../../assets/svg/app-store.svg";
import GooglePlaySvg from "../../../../assets/svg/google-play.svg";
import VideoEmailSvg from '../../../../assets/svg/video-email.svg';
import AttributionModal from "../../../Modals/AttributionModal/AttributionModal";
import { resetSlice } from "../../../../slices/video-slice";
import { push_event_to_datalayer } from '../../../../tools/analytics';
import * as styles from './exportvideo.module.scss';
import { dataURItoBlob, uploadToFirebase } from "../Functions";

const axios = require('axios');

export default (props) => {
    let data = useSelector(state => state.VideoSlice)
    let dispatch = useDispatch()
    const [loading, setLoading] = React.useState(false)
    const [uploadingText, setUploadingText] = React.useState('Please wait...')
    const [email, setEmail] = React.useState('')
    const [finished, setFinished] = React.useState(false)
    const [dimensions_width, setDimensionsWidth] = React.useState(886)
    const [dimensions_height, setDimensionsHeight] = React.useState(1920)



    async function startExport(){
        setLoading(true)
        push_event_to_datalayer('video-maker-export-started');

        let videos = data.videos
        let audio = data.audio
        let axios_videos = []
        let video_counter = 1
        let audio_url = null

        for (let i=0; i<videos.length; i++){
            if(Object.keys(videos[i]).length){
                setUploadingText(`Uploading Video ${video_counter}...`)
                if(videos[i].transition_url){
                    let transition_image_file = await dataURItoBlob(videos[i].transition_url)
                    let video_transition_url = await uploadToFirebase(transition_image_file, 'images', 'png')
                    axios_videos.push({"duration":3, "gs_path": video_transition_url});
                }
                let video_file = await dataURItoBlob(videos[i].video_url)
                let video_url = await uploadToFirebase(video_file, 'videos', video_file.type.replace('video/',''))

                axios_videos.push({"gs_path": video_url})
                video_counter ++
            }
        }

        if(audio){
            let audio_file = await dataURItoBlob(data.audio)
            audio_url = await uploadToFirebase(audio_file, 'audio', audio_file.type.replace('audio/',''))
        }

        axios.post('https://previewedbackend-kisdn3h5rq-uw.a.run.app/api/v1/render/video_full', {
            "video_width": dimensions_width,
            "video_height": dimensions_height,
            "email": email,
            "videos": axios_videos,
            "audio": audio_url
           })
          .then(function (response) {
          })
          .catch(function (error) {
          });

          setLoading(false)
          setFinished(true)
          dispatch(resetSlice())
    }

    return(
        <div className={styles.exportContainer}>
            {finished ?
            <div>
                <AttributionModal />
                <CheckIcon className={styles.tickSvg} />
                <h3>That's it!</h3>
            <h4>The video will be sent to your inbox shortly.</h4>
            <Link 
            to={'/'}
            >
            <button className={styles.finishButton}>Finish</button>
            </Link>
            </div> :
            loading ? 
            <div className={styles.loadingContainer}>
            <h3>{uploadingText}</h3>
            <BarLoader  height={15}
            width={'100%'}
            css={"border-radius: 10px; margin-top: 45px;"}
                    color={"#613EEA"}
                    loading={true}/>
            </div>
            :
            <div>
            <VideoEmailSvg width={200} />
            <h3>You're almost there!</h3>
            <h4>Set your video dimensions and leave us  your e-mail. We will send it to you when it's done.</h4>
            <span className={styles.dimensionsTitle}>Recommended Dimensions</span>
            <div className={styles.recommendedDimensions}>
                <a href={'https://help.apple.com/app-store-connect/?lang=en/#/dev4e413fcb8'} target={'_blank'}>
                <button>
                    <AppStoreSvg />
                <span>App Store</span>
                </button>
                </a>
                <a href={'https://support.google.com/googleplay/android-developer/answer/1078870?hl=en-GB'} target={'_blank'}>
                <button>
                    <GooglePlaySvg />
                <span>Google Play</span>
                </button>
                </a>

                </div>
            <div className={styles.dimensionsDiv}>
                <div>
                <span>Width:</span>
                <input type={'number'} min={0} max={2000} value={dimensions_width} onChange={(e) => setDimensionsWidth(e.target.value)}/>
                </div>
                <span>X</span>
                <div>
                <span>Height:</span>
                <input type={'number'} min={0} max={2000} value={dimensions_height} onChange={(e) => setDimensionsHeight(e.target.value)}/>
                </div>
            </div>
            <input type={'email'} placeholder={'E-mail'} onChange={(e) => setEmail(e.target.value)}/>
            <div className={styles.mainButtons}>
            <button onClick={email && dimensions_height && dimensions_width ? startExport : null} className={email && dimensions_height && dimensions_width && styles.activeExport}>Export</button>
            <button onClick={() => props.setStep(3)}>
                <KeyboardBackspaceIcon fill={'#613eea'} width={'2rem'}/>
                <span>
                Back
                </span>
                </button>
                </div>
                </div>}
        </div>
    )
}