import React from 'react'
import {
  setBackground,
  selectBackground,
  setSelectedId,
  selectCanvasData,
  setTransitionStyle,
  setTransitionUrl
} from "../../../../slices/video-slice"
import * as styles from './transitionsettingsbuttons.module.scss'
import { useSelector, useDispatch } from 'react-redux';

const cloneDeep = require('clone-deep');

export default (props) => {
    let data = useSelector(selectCanvasData)
    const [savedStyle, setSavedStyle] = React.useState(null)

    React.useEffect(() => {
      setSavedStyle(cloneDeep(data))
    }, [])

    let dispatch = useDispatch()

    async function removeTransformerLines(){
      dispatch(setSelectedId('background'))
      return true
    }

    async function clickFinish(){
        await removeTransformerLines()
        let canvasHeight = data.stageRef.attrs.height
        let requiredHeight = data.meta.dimensions.height
        let ratio = requiredHeight/canvasHeight

        // Save screenshot
        
        let img = data.stageRef.toDataURL({
          pixelRatio: ratio // scale it up/down
        })

        dispatch(setTransitionUrl(img))
        props.setShowTransitionEditor(false)
      }
    
      function clickCancel(){
        dispatch(setTransitionStyle(savedStyle))
        props.setShowTransitionEditor(false)
      }
    return(
        <div className={styles.buttonsDiv}>
        <button className={styles.cancelButton} onClick={clickCancel}>Cancel</button>
        <button className={styles.applyButton} onClick={clickFinish}>Apply</button>
      </div>
    )
}