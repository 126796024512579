import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedId } from '../../../../../slices/video-slice';
import BackgroundIcon from '../../../../../assets/svg/feature_graphic.svg';
import ImageIcon from '../../../../../assets/svg/image.svg';
import TextIcon from '../../../../../assets/svg/text.svg';
import * as styles from './sidetabs.module.scss';


export default () => {
    let id = useSelector(state => state.VideoSlice.videos[state.VideoSlice.selectedVideo].transition_style.selectedId)
    let dispatch = useDispatch()

    const menu_items_list = [
      {
        icon: <BackgroundIcon width={14} height={20} />,
        text: 'background',
      },
      {
        icon: <ImageIcon width={40} height={40}/>,
        text: 'image',
      },
      {
        icon: <TextIcon width={40} height={40} />,
        text: 'text',
      },
      // {
      //   icon: <SettingsIcon width={22} height={22} fill={id === 'advanced' ? '#613EEA' : '#838897'} />,
      //   text: 'advanced',
      // },
    ]

    return(
      <div className={styles.sideTabContainer}>
        {menu_items_list.map((item, index) =>{
          return(
      <div onClick={() => dispatch(setSelectedId(item.text))} className={id.includes(item.text) && styles.active}>
        <div className={index !== 1 && index !== 2 && styles.withBorder}>
        {item.icon}
        </div>
        <span style={{fontSize: 12, fontWeight: 500, marginTop: 10, color: id === item.text ? '#613EEA' : '#838897'}}>
        {item.text}
        </span>
      </div>
          )
      })}
      </div>
    )
  }