import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCanvasData, setImages, setSelectedId } from '../../../../../slices/video-slice';
import DeleteButton from '../../../../Common/DeleteButton/DeleteButton';
import UploadMedia from '../../../../Common/UploadMedia/UploadMedia';
import Slider from '../../../../Common/Slider/Slider';
import CollectionComponent from '../../../../Common/CollectionComponent/CollectionComponent';
import * as styles from './imagetab.module.scss';


const cloneDeep = require('clone-deep');


export default () => {
  let data = useSelector(selectCanvasData)
  let dispatch = useDispatch()
  let id = data.selectedId.replace(/\D/g,'');
  let new_images = cloneDeep(data.images)

  if(id){
    var currentImage = data.images[id]
  }
  async function addExtraImage(src){
    let new_images = [...data.images, {x:0,y:0, height:200, width:200, image_url: src, name:'object', id: `image${data.images.length}`}]
    dispatch(setImages(new_images))
  }

function changeImage(param, value){
  new_images[id][param] = value
  dispatch(setImages(new_images))
}

function removeImage(){
  new_images[id] = {}
  dispatch(setSelectedId('image'))
  dispatch(setImages(new_images))
}

  return (
    <div>
      {id ?
      <div className={styles.innerContainer}>
      <h3>Image</h3>
        <img src={data.images[id].image_url} width={'30%'} />
        <UploadMedia onUpload={(url) => changeImage('image_url', url)} label={'Upload Image'}/>
        <h3>
          Size
        </h3>
        <Slider min={0} max={200} value={currentImage.width/10} onChange={(value) => changeImage('width', value*10)} />
        <DeleteButton onClick={removeImage} />
        </div>
        :
        <div className={styles.nothingSelected}>
          <span>
            Click on an image on the right to edit it.

          </span>
          <div className={styles.orSeparator}>
                <span>
                OR
                    </span></div>
                    <span>
                    <br />
                      Choose an image from one of the collections below
                    </span>
                    <CollectionComponent requiredCollections={['Store Badges']} onSelect={addExtraImage} canvasProportion={0} style={{backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center'}} />
                                <div className={styles.orSeparator}>
                <span>
                OR
                    </span></div>
                    <span>
                    <br />
                      Upload your own
                    </span>
                    <UploadMedia onUpload={addExtraImage} label={'Upload Image'}/>
          </div>}
    </div>
  )
  }