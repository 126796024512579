import React from "react";
import { push_event_to_datalayer } from '../../../tools/analytics';
import Stepper from "./Stepper/Stepper";
import AddVideo from "./AddVideo/AddVideo";
import AddTransition from "./AddTransition/AddTransition";
import AddAudio from "./AddAudio/AddAudio";
import ExportVideo from './ExportVideo/ExportVideo'
import KeyboardBackspaceIcon from "../../../assets/svg/backspace.svg";

import * as styles from "./videomaker.module.scss";

export default () => {
  const [step, setStep] = React.useState(1);
  const [next, setNext] = React.useState(false)
  const [show_steps, setShowSteps] = React.useState(true)


  push_event_to_datalayer('video-maker-started');
  return (
    <>
      <div className={styles.phoneDiv}>
          <span>Unavailable on small screens</span>
          <span>Please use a tablet or a desktop to access our maker</span>
      </div>
      <div className={styles.makerMain}>
        
      <Stepper step={step} />

      {step === 1 ? <AddVideo setNext={setNext} /> : 
      step === 2 ?
      <AddTransition setShowSteps={setShowSteps}/> :
      step === 3 ? 
      <AddAudio />
    : <ExportVideo setStep={setStep} />}


      {show_steps && step < 4 ?
      <div className={styles.buttonsDiv}>
          {step > 1?
        <button onClick={() => setStep(step - 1)}>
          <KeyboardBackspaceIcon />
          <span>Back</span>
        </button> : null}
        {next ?
        <button onClick={() => setStep(step + 1)}>
          <span>{step === 1 ? 'Continue to Slides' : step === 2 ?'Continue to Audio' : 'Continue to Export'}</span>
        </button> : null}
      </div> : null }
      </div>
    </>
  );
};
