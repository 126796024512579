import React from "react";
import Layout from '../../components/layout'
import { Helmet } from "react-helmet";
import VideoMaker from '../../components/Makers/2D-video/VideoMaker'

export default () => {
  return (
    <Layout>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Video Maker - Create a stunning app preview for Appstore & GooglePlay</title>
          <meta name="description" content="Create a perfect app preview video to show on the AppStore and GooglePlay page. Follow easy steps to make your video in just a few minutes." />
      </Helmet>
      <VideoMaker />
    </Layout>
  );
};
