import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteTransition, setSelectedVideo } from '../../../../slices/video-slice';
import PlusSvg from '../../../../assets/svg/add-video.svg';
import DeleteSvg from '../../../../assets/svg/delete.svg';
import * as styles from "./transitionoverview.module.scss";

const cloneDeep = require('clone-deep');

export default (props) => {
  const [height, setHeight] = React.useState(200)
  let videos = useSelector(state => state.VideoSlice.videos)
  let dispatch = useDispatch()

    function selectTransition(id){
      dispatch(setSelectedVideo(id))
      props.setShowTransitionEditor(true)
    }

    // React.useEffect(() => {
    //   async function getTransitionHeight(){
    //     let key = await findNonEmptyVideo()
    //     let new_height = 115 * store.getState().videoParams.videos[key].video_dimensions.height/store.getState().videoParams.videos[key].video_dimensions.width;
    //     return new_height
    //   }

    //   getTransitionHeight().then(new_height => {
    //     setHeight(new_height)
    //   })

    // })




  const videoCards = () =>
    videos.map((item, index) => {
      if(Object.keys(item).length !== 0){
        return (
          <div className={styles.cardsContainer} key={index}>
            <div className={`${styles.transitionCard} transition_card`} style={{height: height}}>
              {item.transition_url ? 
                              [<div className={styles.deleteDiv}>
                              <DeleteSvg onClick={() => dispatch(deleteTransition({id: index, dimensions: videos[index].transition_style.meta.dimensions}))
                                }/>
                          </div>,
              <img src={item.transition_url} width={'100%'} height={'100%'} onClick={() => selectTransition(index)}/>]
              :
              <div className={styles.addTransition} onClick={() => selectTransition(index)}> 
              <PlusSvg />
              <span>Add Slide</span>
              </div>}
            </div>
            <div className={styles.videoCard}>
              <video width="100%" height="100%" controls>
                <source src={item.video_url} />
              </video>
            </div>
          </div>
        );
      }
      else {
        return null
      }

    });
  return (
    <div className={styles.transitionOverviewContainer}>{videoCards()}</div>
  );
};
