import React from "react";
import VideoStep1Svg from "../../../../assets/svg/video-step-1.svg";
import VideoStep2Svg from "../../../../assets/svg/video-step-2.svg";
import VideoStep3Svg from "../../../../assets/svg/video-step-3.svg";
import VideoStep4Svg from "../../../../assets/svg/video-step-4.svg";

import * as styles from "./stepper.module.scss";

const steps_info = [
    {
      step: 1,
      title: "Add Videos",
      subtitle: "Add videos from your devices to the storyboard.",
      icon: <VideoStep1Svg />,
    },
    {
      step: 2,
      title: "Add Slides",
      subtitle: "Add explanatory video slides.",
      icon: <VideoStep2Svg />,
    },
    {
      step: 3,
      title: "Add Audio",
      subtitle: "Add an audio background to your video.",
      icon: <VideoStep3Svg />,
    },
    {
      step: 4,
      title: "Export",
      subtitle: "Download your video, then share it with the world.",
      icon: <VideoStep4Svg />,
    },
  ];

  const steps = (props) =>
    steps_info.map((step) => {
      let active = props.step === step.step;
      return (
        <div
          onClick={
            props.completed_steps + 1 >= step.step
              ? () => props.goToStep(step.step)
              : null
          }
          className={active && styles.active}
        >
          <div className={styles.leftDiv}>
            <div className={styles.circle}>{step.icon}</div>
          </div>
          <div className={styles.rightDiv}>
            <h3 className={styles.title}>
              {step.step}. {step.title}
            </h3>
            <span className={styles.subtitle}>{step.subtitle}</span>
          </div>
        </div>
      );
    });

export default (props) => {

  return (
  <div className={styles.stepperMain}>
      {steps(props)}
    </div> 
    );
};
