import FontPicker from "font-picker-react";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCanvasData, setSelectedId, setTexts } from '../../../../../slices/video-slice';
import AddButton from '../../../../add_button';
import Alignment from "../../../../Common/Alignment/Alignment";
import ColorPicker from '../../../../Common/ColorPicker/ColorPicker';
import DeleteButton from "../../../../Common/DeleteButton/DeleteButton";
import Select from "../../../../Common/Select/Select";
import Slider from '../../../../Common/Slider/Slider';
import TrueFalse from "../../../../Common/TrueFalse/TrueFalse";
import * as styles from './texttab.module.scss';



const cloneDeep = require('clone-deep');

if (typeof window !== 'undefined'){ 
    var WebFont = require('webfontloader');
}
export default () => {
    let data = useSelector(selectCanvasData)
    let dispatch = useDispatch()
    let id = data.selectedId.replace(/\D/g,'');
    let new_texts = cloneDeep(data.texts)
    let [fontWeights, setFontWeights] = React.useState([])

    if(id){
        var currentText = data.texts[id]
        var currentFontWeight = currentText.fontStyle.replace(/\D/g,'')
    }

    React.useEffect(() => {
        async function getFontOnPageLoad(){
            if(id){
                let newWeights = await fetchNewWeights(new_texts[id].fontFamily)
                setFontWeights(newWeights)
            }
        }
        getFontOnPageLoad()
    }, [id])

    async function fetchNewWeights(family){
        let response = await fetch('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDqZriFjXj28L5mKnwtU5DBGkmT6t25MuU')
        let responseJson = await response.json() 
        for(let i=0; i<responseJson.items.length; i++){
            if(family === responseJson.items[i].family){
                let newWeights = getWeights(responseJson.items[i].variants)
                return newWeights
            } 
        }
        return []
    }

    function getWeights(variants){
        let newWeights = variants.reduce((list, variant) => {
            if(/^\d+$/.test(variant)){
                list.push(variant)
            }
            return list
        }, [])
        if(newWeights.length === 0) {
            newWeights = ['400']
        }
        return newWeights
    }



    async function changeText(param, value){
        let text = new_texts[id]
        text[param] = value

        if(param === 'fontFamily'){
                text[param] = value.family
                let newWeights = await fetchNewWeights(value.family)

                if(!newWeights.includes(text.fontStyle.replace(/\D/g,''))){
                    text.fontStyle = text.fontStyle.replace(/[0-9]/g,'') + newWeights[0] // If current weight isn't available in the new family, change it to first available weight
                }
                setFontWeights(newWeights)
        }
        dispatch(setTexts(new_texts))
    }

    function removeText(){
        new_texts[id] = {}
        dispatch(setSelectedId('text'))
        dispatch(setTexts(new_texts))
    }

    function addExtraText() {
        let newTexts = [...new_texts, {x:0,y:0, name:'object', text:'New Text', id: `text${new_texts.length}`, fontStyle: '300', fontFamily:'Poppins', scale:{x: 50, y: 50,}, align: 'left', fill: 'black'}]
        dispatch(setTexts(newTexts))
      }
    return(
        <div className={styles.textTabContainer}>
            {id ?
            <>
            <div className={styles.textTabOne}>
            <div>
            <h3>Text</h3>
            <textarea onChange={(e) => changeText('text', e.target.value)} value={currentText.text} placeholder={`Placeholder`} cols="30" rows="5" />
            </div>
            <div>
            <h3>Size</h3>
            <Slider min={0} max={200} value={currentText.scale.x} onChange={(value) => changeText('scale', {x: value, y:value})} />
            </div>
            <div>
            <h3>Color</h3>
            <ColorPicker onChange={(color) => changeText('fill', color)} startColor={currentText.fill}/>
            </div>
            </div>
            <div className={styles.textTabTwo}>
              <div>


            <h3>Family</h3>
            {typeof window !== 'undefined' && typeof document !== 'undefined'? 
            <FontPicker
                    apiKey="AIzaSyDqZriFjXj28L5mKnwtU5DBGkmT6t25MuU"
                    activeFontFamily={data.texts[id].fontFamily}
                    onChange={(nextFont) => nextFont.family !== currentText.fontFamily ? changeText('fontFamily', nextFont) : null}
                    key={id}
                /> : null}
            </div>
            <div>
            <h3>Weight</h3>
            <Select options={fontWeights} onChange={(value) => changeText('fontStyle', currentText.fontStyle.replace(/[0-9]/g,'') + value)} value={currentText.fontStyle.replace(/\D/g,'')} />
            </div>
            <div>
            <h3>Italic</h3>
            <TrueFalse active={currentText.fontStyle.includes('italic')} onClick={(option) => changeText('fontStyle', option ? `italic ${currentFontWeight}` : currentFontWeight)}/>
            </div>
            </div>
            <div className={styles.textTabThree}>
              <div>

            <h3>Alignment</h3>
            <Alignment active={currentText.align} onClick={(option) => changeText('align', option)} />
            </div>
            <DeleteButton onClick={removeText} />
            </div>
            </>
            :
            <div className={styles.nothingSelected}>
            <span>
            Click on a text on the left to edit it.
            </span>
            <div className={styles.orSeparator}>
                <span>
                OR
                    </span></div>
          <AddButton text={'Add Text'} onClick={addExtraText} />
                </div>}
        </div>
    )
}