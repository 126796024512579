import React from 'react'
import AddBoxIcon from '../assets/svg/add.svg';

export default function AddButton(props){
    return(
        <div style={{display: 'flex',padding: '20px 26px', background: '#EDF0F5',borderRadius: 10, width: 'max-content', marginTop: 10, color: '#613EEA', cursor: 'pointer', alignItems: 'center',}} onClick={props.onClick}>
            {props.icon ? 
            props.icon :
            <AddBoxIcon fill={'inherit'} height={'1rem'}  width={'1rem'} fill={'#613eea'} />}
            <span style={{fontSize: 18, fontWeight: 400, marginLeft: 10}}>{props.text}</span>
        </div>
    )
}