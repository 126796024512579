import React from 'react';
import BackgroundPicker from '../../../../Common/BackgroundPicker/BackgroundPicker';
import { useSelector, useDispatch } from "react-redux"
import {
  setBackground,
  selectCanvasData
} from "../../../../../slices/video-slice"

import * as styles from './backgroundtab.module.scss'

export default () => {
    const data = useSelector(selectCanvasData)
    let dispatch = useDispatch()
      return(
        <div className={styles.backgroundTabContainer}>
        <h3>Background</h3>
        <BackgroundPicker background={data.background} meta={data.meta} setBackground={(value) => dispatch(setBackground(value))}/>
    </div>
      )
   }