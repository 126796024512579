import React from 'react'
import Dropzone from '../../../dropzone'
import {
    setAudio,
} from "../../../../slices/video-slice";

import * as styles from './addaudio.module.scss'
import { useSelector, useDispatch } from 'react-redux';

export default () => {
    let audio = useSelector(state => state.VideoSlice.audio)
    let dispatch = useDispatch()

    function onAudioUpload(file) {
        dispatch(setAudio(file))
      }

    function removeAudio(){
        dispatch(setAudio(''))
    }
    return(
        <div className={styles.audioContainer}>
            <h4>
                Add Audio to your preview video <span>(optional)</span>
            </h4>
            <div className={styles.dropzoneOuter}>
                <Dropzone onUpload={(file) => onAudioUpload(file)} accepted_files={"audio/*"} uploaded={audio} name={"audio"} />
                {audio ? 
                <div>
                    <button onClick={removeAudio} className={styles.removeButton}>
                        Remove Audio
                    </button>
                </div> :null}
            </div>

        </div>
    )
}