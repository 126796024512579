import { v4 as uuidv4 } from "uuid";
import { store } from '../../../../configureStore';
import { storage } from "../../../firebase/index";

export function uploadToFirebase(item, folder, extension) {
  return new Promise((resolve, reject) => {
    let uuid = uuidv4() + `.${extension}`;
    const uploadTask = storage.ref(`${folder}/${uuid}`).put(item);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // progress function ...
        // const progress = Math.round(
        //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        // );
      },
      (error) => {
        // Error function ...

      },
      () => {
        // complete function ...
        storage
          .ref(folder)
          .child(uuid)
          .getDownloadURL()
          .then((url) => {
            let new_url = url
              .replace(
                "https://firebasestorage.googleapis.com/v0/b/promopreview/o/",
                ""
              )
              .split("?")[0]
              .replace(/%2F/g, "/");
            resolve(new_url);
          });
      }
    );
  });
}



export function findNonEmptyVideo(){
  return new Promise((resolve, reject) => {
    Object.keys(store.getState().videoParams.videos).forEach(key =>{
      if(Object.keys(store.getState().videoParams.videos[key]).length !== 0){
        resolve(key)
      }
    })
    resolve(null)
  })
}

export async function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
  else
      byteString = unescape(dataURI.split(',')[1]);
  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], {type:mimeString});
  }