import React from 'react'
import { useSelector } from 'react-redux'

import * as styles from './displayedtab.module.scss'
import BackgroundTab from '../BackgroundTab/BackgroundTab'
// import DeviceTab from '../DeviceTab/DeviceTab'
import AdvancedTab from '../AdvancedTab/AdvancedTab'
import ImageTab from '../ImageTab/ImageTab'
import TextTab from '../TextTab/TextTab'

export default () => {
    let id = useSelector(state => state.VideoSlice.videos[state.VideoSlice.selectedVideo].transition_style.selectedId)

    function returnCorrectTab(){
        if(id === 'advanced'){
            return <AdvancedTab />
        }
        else if(id.includes('image')){
            return <ImageTab />
        }
        else if(id.includes('text')){
            return <TextTab />
        }
        else {
            return <BackgroundTab />
        }
    }
    return(
        <div className={styles.mainContainer}>
            {returnCorrectTab()}
        </div>
    )
}