import React from "react";
import DisplayedTab from '../Tabs/DisplayedTab/DisplayedTab';
import SideTabs from "../Tabs/SideTabs/SideTabs";
import * as styles from "./transitionsettings.module.scss";


export default () => {

  return (
    <div className={styles.mainContainer} id={'transition_settings'}>
      <SideTabs />
      <div className={styles.tabContent}>
        <DisplayedTab />
      </div>
    </div>
  );
};
