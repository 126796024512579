import React from 'react'
import TransitionOverview from '../TransitionOverview/TransitionOverview'
import TransitionCanvas from '../../2D-image/Canvas/Canvas'
import TransitionSettings from '../TransitionSettings/TransitionSettings'
import TransitionSettingsButtons from '../TransitionSettingsButtons/TransitionSettingsButtons'
import {
    setDevices,
    setImages,
    setSelectedId,
    setTexts,
    setStageRef,
    selectCanvasData
} from "../../../../slices/video-slice";

import * as styles from './addtransition.module.scss'
const cloneDeep = require('clone-deep');

export default (props) => {
    const [canvasHeight, setCanvasHeight] = React.useState(500)
    const [showTransitionEditor, setShowTransitionEditor] = React.useState(false)
    const stageRef = React.useRef();
    // TransitionStyle().then(result => {
    //     store.dispatch(setVideos({transition: {groups: cloneDeep(result), background: cloneDeep(background)}}, 0))
    //     setReady(true)
    // })

    React.useEffect(() => {
        if(showTransitionEditor === true){
            props.setShowSteps(false)
        }
        else{
            props.setShowSteps(true)
        }
    }, [showTransitionEditor])

    React.useEffect(() => {
        if(showTransitionEditor){
            let settingsHeight = document.getElementById("transition_settings").offsetHeight
            setCanvasHeight(settingsHeight)
        }
    },[showTransitionEditor])
    return(
        <div>

        {showTransitionEditor ?
        <div className={styles.transitionContainer}>
            <div id={'main-div-id'} className={styles.transitionContainerInner}>
            <div
              id={"stage-parent"}
              style={{
                  display: 'flex',
                  flex: 1,
                height: canvasHeight,
              }}
            >
                <TransitionCanvas selector={selectCanvasData} setDevices={setDevices} setImages={setImages} setSelectedId={setSelectedId} setTexts={setTexts} setStageRef={setStageRef} video />
                </div>
                <TransitionSettings />
            </div>
            <TransitionSettingsButtons setShowTransitionEditor={setShowTransitionEditor} stageRef={stageRef}/>
        </div> :
        <TransitionOverview setShowTransitionEditor={setShowTransitionEditor} />}
        </div>

    )
}