import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setImages, setTexts, selectCanvasData } from '../../../../../slices/video-slice';
import AddButton from '../../../../add_button';

export default () => {
    let data = useSelector(selectCanvasData)
    let dispatch = useDispatch()
      
    // function addExtraText() {
    //     let new_texts = [...data.texts, {x:0,y:0, name:'object', text:'New Text', id: `text${data.texts.length}`, fontStyle: '400', fontFamily:'Lato', scale:{x: 9, y: 9,}, align: 'left', fill: 'black'}]
    //     dispatch(setTexts(new_texts))
    //   }
      
    // async function addExtraImage(){
    //     let image_placeholder = require('../../../../assets/images/custom_images/heart.png')
    //     let new_images = [...data.images, {x:0,y:0, height:200, width:200, image_url: image_placeholder, name:'object', id: `image${data.images.length}`}]
    //     dispatch(setImages(new_images))
    //   }

    return(
        <div>
            {/* <AddButton text={'Add Text'} onClick={addExtraText} />
            <AddButton text={'Add Image'} onClick={addExtraImage} /> */}
        </div>
    )
}